import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { getSurveyData, postSurveyData } from '../../../../services/api';
import SurveyContent from './surveyContent';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Survey extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        data: {},
        status: 0,
        selectValue: {},
        valueCheck: [],
        resultShow: false,
        tipShow: false,
        isSubmit: false,
    };

    async componentDidMount() {
        try {
            const { content } = this.props;

            const data = await getSurveyData(Number(content.attachmentId));

            if (data.ifsuccess) {
                this.setState({
                    data: data.data,
                    status: data.ifsuccess,
                    resultShow: data.data.surveyinfo.expire === '0',
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleSubmit = async () => {
        try {
            const { values, entries } = Object;
            const { data, selectValue } = this.state;
            const valueCheck = [];
            const result = data.result;

            result.forEach((item, index) => {
                // 1 必答 0 选答
                const ismust = item.resultArray.ismust;

                if (ismust) {
                    if (values(selectValue).length === 0) {
                        valueCheck.push(index);
                    }
                    for (const v of values(selectValue)) {
                        if (v === '' || v.length === 0) {
                            valueCheck.push(index);
                        }
                    }
                }
            });

            this.setState({ valueCheck });

            if (valueCheck.length === 0) {
                const { content } = this.props;
                const value = [];

                for (const [k, v] of entries(selectValue)) {
                    if (k && v) {
                        if (typeof v === 'string') {
                            value.push(`sur[${k}][]=${v}`);
                        } else {
                            v.forEach(item => {
                                value.push(`sur[${k}][]=${item}`);
                            });
                        }
                    }
                }

                const survey = await postSurveyData(value, content.attachmentId);

                this.setState({
                    resultShow: true,
                });

                if (survey.ifsuccess) {
                    this.setState({
                        isSubmit: true,
                    });
                }

                window.alert(survey.msg);
            }
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const { attachmentId } = this.props.content;
        const { data, status, valueCheck, selectValue, resultShow, tipShow, isSubmit } = this.state;
        const url = `//news.ifeng.com/mil/survey.html#id=${attachmentId}&result`;

        let props = {};

        if (isSubmit) {
            props = { disabled: 'disabled' };
        }

        return (
            <div className={styles.survey}>
                {status === 1 ? (
                    <div className={styles.survey_box}>
                        <div className={styles.survey_content}>
                            <h4 className={styles.title}>
                                <a href={url} target="_blank" ref={relText}>
                                    调查
                                </a>
                            </h4>
                            <SurveyContent
                                data={data}
                                disabled={data.surveyinfo.expire === '0'}
                                valueCheck={valueCheck}
                                selectValue={selectValue}
                                resultShow={resultShow}
                            />
                            {data.surveyinfo.expire === '0' ? (
                                [
                                    <div key="survey_end" className={styles.survey_end}>
                                        抱歉，本调查已经结束。
                                    </div>,
                                    <div key="all" className={styles.all}>
                                        <a href={url} target="_blank" ref={relText}>
                                            {'点击查看结果>>'}
                                        </a>
                                    </div>,
                                ]
                            ) : (
                                <div>
                                    {tipShow ? <div className={styles.tip}>请选择您的观点，然后提交</div> : ''}
                                    <div className={styles.submit}>
                                        <button
                                            type="button"
                                            className={isSubmit ? styles.submited_btn : styles.submit_btn}
                                            onClick={this.handleSubmit}
                                            {...props}>
                                            {isSubmit ? '已提交' : '提交'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    this.props.content.attachmentId
                )}
            </div>
        );
    }
}

export { Survey };
export default Survey;
