import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';
import News from './news';
import SliderBox from './sliderBox';

class NewsAndSlider extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            newsRecommend0,
            newsRecommend1,
            newsRecommend2,
            newsRecommend3,
            newsSelectedPool,
            newsSlider,
            sliderAd,
            newsAd,
        } = this.props.content;
        const newsData = {
            newsRecommend0,
            newsRecommend1,
            newsRecommend2,
            newsRecommend3,
            newsSelectedPool,
            newsAd,
        };

        return (
            <div className={classNames(styles.newsAndSlider, 'clearfix')}>
                <News content={newsData} ad={newsAd} />
                <SliderBox content={newsSlider} ad={sliderAd} />
            </div>
        );
    }
}

export default errorBoundary(NewsAndSlider);
