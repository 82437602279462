import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatUrl } from '@ifeng-fe/public_method';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Tabs extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    state = {
        index: 0,
    };

    // 样式
    styles(tabIndex) {
        const { index } = this.state;

        return index === tabIndex ? styles.itis : undefined;
    }
    // 改变选项卡
    handleChangeIndex(index, event) {
        this.setState({ index });
    }
    // 渲染列表
    listView(list) {
        return list.map((item, index) => {
            const url = formatUrl(item.url);

            return (
                <li key={index}>
                    <i className={index < 3 ? styles.number1 : styles.number2}>{index + 1}</i>
                    <a href={url} title={item.title} target="_blank" rel={relText}>
                        {item.title}
                    </a>
                </li>
            );
        });
    }
    render() {
        const { content } = this.props;
        const { index } = this.state;

        return [
            <ul key="tab" className={classNames(styles.tabs, 'clearfix')}>
                <li className={this.styles(0)} onMouseOver={this.handleChangeIndex.bind(this, 0)}>
                    <a href="//news.ifeng.com/mil/index-phb.shtml" target="_blank" rel={relText}>
                        今日排行
                    </a>
                </li>
                <li className={this.styles(1)} onMouseOver={this.handleChangeIndex.bind(this, 1)}>
                    <a href="//news.ifeng.com/mil/index-phb.shtml" target="_blank" rel={relText}>
                        昨日排行
                    </a>
                </li>
                <li className={this.styles(2)} onMouseOver={this.handleChangeIndex.bind(this, 2)}>
                    <a href="//news.ifeng.com/mil/index-phb.shtml" target="_blank" rel={relText}>
                        一周排行
                    </a>
                </li>
            </ul>,
            <ul key="list" className={styles.list}>
                {this.listView(content[index])}
            </ul>,
        ];
    }
}

export default Tabs;
