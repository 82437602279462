import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Footer 组件
 */
const Footer = errorBoundary(
    class extends React.PureComponent {
        static propTypes = { content: PropTypes.array };

        // 渲染链接
        renderLink() {
            const { content } = this.props;
            const [link, copyRight] = [[], []];
            const endIndex = content.length - 1;

            content.forEach((item, index) => {
                if (index === endIndex) {
                    copyRight.push([
                        <span key="copyRight0">{item.text0}</span>,
                        <span key="copyRight1">{item.text1}</span>,
                        <span key="copyRight2">{item.text2}</span>,
                    ]);
                } else {
                    link.push(
                        <a key={index} className={styles.link} href={item.url} target="_blank" rel={rel}>
                            {item.title}
                        </a>,
                    );
                }
            });

            return [link, copyRight];
        }

        /**
         * 渲染组件
         */
        render() {
            const [link, copyRight] = this.renderLink();

            return (
                <div className={styles.footer}>
                    <div className={`${styles.foot_link} clearfix`}>
                        <div className={styles.list}>{link}</div>
                    </div>
                    <div className={styles.copyright}>{copyRight}</div>
                </div>
            );
        }
    },
);

export { Footer };
export default Footer;
