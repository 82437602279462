import { jsonp } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

/* 军事的调查 */
// 获取调查数据
export const getSurveyData = async id => {
    const { schemaCheck } = await import('./validate');

    let data = await jsonp('//sv.ifeng.com/index.php/survey/getdata', {
        data: {
            id,
        },
        jsonpCallback: 'getSurveyData',
        cache: false,
    });
    const requestUrl = `sv.ifeng.com/index.php/survey/getdata?id=${id}&callback=getSurveyData`;

    data = schemaCheck(data, 'surveyDataSchema', requestUrl);

    return data ? data : null;
};

// 提交调查数据
export const postSurveyData = async (param, id) => {
    return await jsonp(`//sv.ifeng.com/index.php/survey/postsurvey?${param}`, {
        data: {
            id,
        },
        jsonpCallback: 'postSurveyData',
        cache: false,
    });
};
