import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

class NewsGroupListView extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    groupView(item0, item1) {
        const url0 = formatUrl(item0.url);
        const url1 = formatUrl(item1.url);
        const image1 = formatImage(item1.thumbnail, 190, 124);

        return [
            <h4 key="title" className={styles.title}>
                <a href={url0} target="_blank" rel={relText}>
                    {item0.title}
                </a>
            </h4>,
            <a key="thumbnail" className={styles.image} href={url1} target="_blank" rel={relText}>
                <img src={image1} alt={item1.title} width={190} height={124} />
            </a>,
            <p key="link" className={styles.link}>
                <a href={url1} target="_blank" rel={relText}>
                    {item1.title}
                </a>
            </p>,
        ];
    }
    newsGroupListView(list) {
        const element = [];

        for (let i = 0; i < 8; i += 2) {
            element.push(
                <div key={i} className={classNames(i % 4 === 0 ? styles.fl : styles.fr, styles.group)}>
                    {this.groupView(list[i], list[i + 1])}
                </div>,
            );
        }

        return element;
    }
    render() {
        const { content } = this.props;

        return <div className="clearfix">{this.newsGroupListView(content)}</div>;
    }
}

class FourRecommendNews extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { fourRecommendNews, sizeW400H150Ad } = this.props.content;

        return (
            <div className={styles.newsBox}>
                <Chip id={55038} type="recommend" title="推荐位8条" groupName="推荐位8条" content={fourRecommendNews}>
                    <NewsGroupListView />
                </Chip>
                <NoSSR onSSR={<div className={styles.ad} />}>
                    <Ad key="ad" styleName={styles.ad} content={sizeW400H150Ad} />
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(FourRecommendNews);
