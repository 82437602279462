import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Footer from './footer';

/**
 * 定义 Footer 组件
 */
class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.footer}>
                <Chip id="20012" type="struct" title="底部公用版权" groupName="底部" content={content.footer}>
                    <Footer />
                </Chip>
            </div>
        );
    }
}

export { BottomFooter };
export default BottomFooter;
