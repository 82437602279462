import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Ad from '@ifeng-fe/ui_pc_ad';

class EtcAd extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { float1Ad, float2Ad, float3Ad, float4Ad, float5Ad } = this.props.content;

        return (
            <div>
                <NoSSR>
                    <Ad content={float1Ad} />
                    <Ad content={float2Ad} />
                    <Ad content={float3Ad} />
                    <Ad content={float4Ad} />
                    <Ad content={float5Ad} />
                </NoSSR>
            </div>
        );
    }
}

export default EtcAd;
