import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import Slides from '@ifeng-fe/ui_pc_slides';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Zhuanti extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    constructor(...argu) {
        super(...argu);

        const { content } = this.props;

        this.state = {
            slidesContent: content.slice(0, 3),
            topContent: content.slice(3, 4),
            content: content.slice(4, content.length),
        };
    }
    sliderTmplView(item) {
        return (
            <div className={styles.box} ifeng_ui_pc_sliders-link="">
                <a
                    className={styles.imageLink}
                    href={formatUrl(item.url)}
                    target="_blank"
                    rel={relText}
                    ifeng_ui_pc_sliders-image="">
                    <img
                        className={styles.image}
                        src={formatImage(item.thumbnails, 300, 170)}
                        title={item.summary || item.title}
                    />
                </a>
            </div>
        );
    }
    listView(list) {
        return list.map((item, index) => {
            return (
                <li key={index}>
                    <a href={formatUrl(item.url)} title={item.title} target="_blank" rel={relText}>
                        {item.title}
                    </a>
                </li>
            );
        });
    }
    render() {
        const { slidesContent, topContent, content } = this.state;
        const isOne = slidesContent.length !== 1;

        return (
            <div className={styles.zhuanti}>
                <h4 className={styles.title}>专题</h4>
                <div className={styles.slidesBox}>
                    <Slides
                        content={slidesContent}
                        config={{
                            dotStyle: styles.dot,
                            dotCurrentStyle: styles.dotCurrent,
                            autoplayInterval: 5000,
                            sliderTmpl: this.sliderTmplView,
                            autoplay: isOne,
                            arrows: isOne,
                            dots: isOne,
                        }}
                    />
                </div>
                <h4 className={styles.secondTitle}>
                    <a href={formatUrl(topContent[0].url)} target="_blank" rel={relText}>
                        {topContent[0].title}
                    </a>
                </h4>
                <ul className={styles.list}>{this.listView(content)}</ul>
            </div>
        );
    }
}

export default errorBoundary(Zhuanti);
