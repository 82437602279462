import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Search from '@ifeng-fe/ui_pc_search';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';

const Logo = errorBoundary(
    class extends PureComponent {
        static propTypes = {
            content: PropTypes.object,
        };

        render() {
            const { content } = this.props;
            const { search, guanmingAd } = content;

            return (
                <div className={classNames(styles.logoBox, 'clearfix')}>
                    <div className={styles.logo}>
                        <a href="//news.ifeng.com/mil/" target="_blank" rel={relText}>
                            <img className={styles.logoMil} src={require('./logoMil.png')} alt="凤凰网军事" />
                        </a>
                        <img
                            className={styles.slogem}
                            src={require('./slogen_mil.jpg')}
                            alt="专业的全方位军事话题平台"
                        />
                    </div>
                    {/* 广告 */}
                    <NoSSR onSSR={<div className={styles.ad} />}>
                        <Ad styleName={styles.ad} content={guanmingAd} />
                    </NoSSR>
                    <NoSSR>
                        <Search content={search} />
                    </NoSSR>
                </div>
            );
        }
    },
);

export { Logo };
export default Logo;
