import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Slides from '@ifeng-fe/ui_pc_slides';
import { Event as UIBaseEvent } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/handleAd';

class SliderBox extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    state = {
        content: this.formatData(this.props.content),
    };

    componentDidMount() {
        const { ad } = this.props;

        // 插入广告
        if (ad) this.getAd(ad);
    }
    // 获取广告
    async getAd(ad) {
        const len = this.state.content.length;
        const callback = await handleAd(ad);
        const event = new UIBaseEvent();

        callback(ad.data, event, this.insert);

        event.trigger('init', { len, index: 0 });
    }
    insert = (insertArr, replaceArr, adFn) => {
        console.log('Slider Callback.', insertArr, replaceArr, adFn);

        if (adFn && typeof adFn === 'function') {
            const { content } = this.state;

            const infoData = [...content];
            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    content: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }
    };
    // 格式化数据
    formatData(data) {
        return data.map(item => ({
            thumbnails: formatImage(item.thumbnails, 570, 332),
            url: formatUrl(item.url),
            title: item.title,
        }));
    }
    // 轮播图模板
    sliderTmplView(item) {
        if (item.type === 'ad') {
            return <div ref={item.ref} className={styles.box} />;
        }

        return (
            <div className={styles.box} ifeng_ui_pc_sliders-link="">
                <a
                    className={styles.imageLink}
                    href={item.url}
                    target="_blank"
                    rel={relText}
                    ifeng_ui_pc_sliders-image=""
                    data-innermask>
                    <img className={styles.image} src={item.thumbnails} alt={item.title} width={570} height={332} />
                </a>
                <div className={styles.link} ifeng_ui_pc_sliders-title="">
                    <a href={item.url} target="_blank" rel={relText} data-innermask>
                        {item.title}
                    </a>
                </div>
            </div>
        );
    }
    render() {
        const { content } = this.state;
        const isOne = !(content.length === 1);

        return (
            <Chip id="75018" type="recommend" title="移动端轮播图" groupName="移动端轮播图">
                <div className={styles.sliderBox}>
                    <Slides
                        content={content}
                        config={{
                            dotStyle: styles.dot,
                            dotCurrentStyle: styles.dotCurrent,
                            autoplayInterval: 10000,
                            sliderTmpl: this.sliderTmplView,
                            dotsAction: 'hover',
                            autoplay: isOne,
                            arrows: isOne,
                            dots: isOne,
                        }}
                    />
                </div>
            </Chip>
        );
    }
}

export default errorBoundary(SliderBox);
