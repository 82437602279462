import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';

const Nav = errorBoundary(
    class extends PureComponent {
        static propTypes = {
            content: PropTypes.object,
        };

        // 渲染导航
        navView(list = []) {
            return list.map((item, index) => (
                <li key={index} className={index === 0 ? styles.firstNav : undefined}>
                    <a href={item.url} target="_blank" rel={relText}>
                        {item.title}
                    </a>
                </li>
            ));
        }
        render() {
            const { topNav } = this.props.content;

            if (topNav) {
                return (
                    <div className={classNames(styles.navBox, 'nav_box')}>
                        <ul className={classNames(styles.nav, 'clearfix', 'nav')}>{this.navView(topNav)}</ul>
                    </div>
                );
            } else {
                return null;
            }
        }
    },
);

export { Nav };
export default Nav;
