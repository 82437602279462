import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

class MilCommit extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    listView(list) {
        const element = [];

        for (let i = 1, j = list.length; i < j; i++) {
            const { url, title } = list[i];
            const url2 = formatUrl(url);

            element.push(
                <li key={i} className={styles.item}>
                    <a href={url2} title={title} target="_blank" rel={relText}>
                        {title}
                    </a>
                </li>,
            );
        }

        return element;
    }
    render() {
        const { content } = this.props;

        if ((Array.isArray(content) && content.length === 0) || !Array.isArray(content)) {
            return null;
        }

        const { title, summary, thumbnails, url } = content[0];
        const image = thumbnails ? formatImage(thumbnails) : '';
        const url2 = formatUrl(url);

        return (
            <div className={styles.milCommit}>
                <h4 className={styles.title}>凤凰军评</h4>
                <ul className={styles.list}>
                    <li key="firstItem" className={classNames(styles.firstItem, 'clearfix')}>
                        <h6 className={classNames(styles.firstItemTitle)}>
                            <a href={url2} title={title} target="_blank" rel={relText}>
                                {title}
                            </a>
                        </h6>
                        <div className="clearfix">
                            <a className={styles.imgLink} href={url2} title={title} target="_blank" rel={relText}>
                                <img src={formatImage(image, 100, 55)} alt={summary} width={100} height={55} />
                            </a>
                            <p className={styles.p}>
                                {summary}
                                <a className={styles.xiangqing} href={url2} title={title} target="_blank" rel={relText}>
                                    [详情]
                                </a>
                            </p>
                        </div>
                    </li>
                    {this.listView(content)}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(MilCommit);
