import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import { formatUrl } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Ad from '@ifeng-fe/ui_pc_ad';
import Chip from 'Chip';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import Tabs from './tabs';

class Tuijian extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return (
            <p className={styles.tuijian}>
                <a href={formatUrl(content.url)} title={content.title} target="_blank" ref={relText}>
                    {content.title}
                </a>
            </p>
        );
    }
}

class Leaderboard extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { sizeW300H200Ad, leaderboard, recommendOne } = this.props.content;

        return (
            <div className={styles.box}>
                <NoSSR onSSR={styles.ad}>
                    <Ad styleName={styles.ad} content={sizeW300H200Ad} />
                </NoSSR>
                {/* 选项卡 */}
                <Tabs content={leaderboard} />
                {/* 军事推荐 */}
                <h4 className={styles.tuijianTitle}>
                    <a href="#" target="_blank" rel={relText}>
                        军事推荐
                    </a>
                </h4>
                <Chip id="55041" type="recommend" title="军事推荐" groupName="军事推荐" content={recommendOne[0]}>
                    <Tuijian />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(Leaderboard);
