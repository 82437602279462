import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NewsStream } from '@ifeng-fe/news-stream';
import '@ifeng-fe/news-stream/lib/NewsStream/style/news-stream-global.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';

const cutImage = {
    oneImage: {
        width: 198,
        height: 112,
    },
    threeImage: {
        width: 198,
        height: 112,
    },
};

class TabsNews extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    moreUrl = [
        '//mil.ifeng.com/shanklist/14-35083-',
        '//mil.ifeng.com/shanklist/14-35086-',
        '//mil.ifeng.com/shanklist/14-35082-',
        '//mil.ifeng.com/shanklist/14-35081-',
    ];
    tabs = ['军情热点', '战争历史', '视频集锦', '精彩图片'];

    constructor(...argu) {
        super(...argu);

        const { content, ad } = this.props;
        const { moreUrl } = this;

        this.state = {
            index: 0,
            element: [
                <NewsStream
                    key={0}
                    content={content[0]}
                    isCount={false}
                    moreHref={moreUrl[0]}
                    ad={[ad]}
                    cutImage={cutImage}
                />,
                null,
                null,
                null,
            ],
        };
    }
    // 样式
    styles(tabIndex) {
        const index = this.state ? this.state.index : 0;

        return index === tabIndex ? styles.itis : undefined;
    }
    block(tabIndex) {
        const index = this.state ? this.state.index : 0;

        return { display: index === tabIndex ? 'block' : 'none' };
    }
    // 改变选项卡
    handleChangeIndex(index, event) {
        const { element } = this.state;
        const { content, ad } = this.props;
        const newState = { index };

        if (!element[index]) {
            element[index] = (
                <NewsStream
                    key={index}
                    content={content[index]}
                    isCount={false}
                    moreHref={this.moreUrl[index]}
                    ad={[ad]}
                    cutImage={cutImage}
                />
            );

            newState.element = element;
        }

        this.setState(newState);
    }
    // 渲染选择器
    tabsView() {
        return this.tabs.map((item, index) => (
            <li key={index} className={this.styles(index)} onMouseOver={this.handleChangeIndex.bind(this, index)}>
                {item}
            </li>
        ));
    }
    // 渲染数据
    listView() {
        return this.state.element.map((item, index) => (
            <div key={index} style={this.block(index)}>
                {item}
            </div>
        ));
    }
    render() {
        return (
            <div className={styles.tabsNews}>
                <ul className={classNames(styles.tabs, 'clearfix')}>{this.tabsView()}</ul>
                {this.listView()}
            </div>
        );
    }
}

export default errorBoundary(TabsNews);
