import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';
import HtmlRegion from '@ifeng-fe/container_channel/common/client/components/htmlRegion';
import Header from './header';
import Logo from './logo';
import Nav from './nav';
import NewsAndSlider from './newsAndSlider';
import FourRecommendNews from './fourRecommendNews';
import SurveyAndVideo from './surveyAndVideo';
import Leaderboard from './leaderboard';
import TabsNews from './tabsNews';
import MilCommit from './milCommit';
import Zhuanti from './zhuanti';
import VideoHighlights from './videoHighlights';
import BottomFooter from './bottomFooter';
import EtcAd from './etcAd';

const Layout = errorBoundary(
    class extends PureComponent {
        static propTypes = {
            content: PropTypes.object,
        };

        componentDidMount() {
            // 上报首屏时间
            if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
        }

        render() {
            const { content } = this.props;
            const headerData = { nav: content.nav };
            const logoData = {
                search: content.search,
                guanmingAd: content.guanmingAd,
            };
            const navData = { topNav: content.topNav };
            const newsAndSliderData = {
                ...content.news,
                newsAd: content.newsAd,
                sliderAd: content.sliderAd,
            };
            const fourRecommendNewsData = {
                ...content.fourRecommendNews,
                sizeW400H150Ad: content.sizeW400H150Ad,
            };
            const surveyAndVideoData = { ...content.surveyAndVideo };
            const leaderboardData = {
                ...content.leaderboard,
                sizeW300H200Ad: content.sizeW300H200Ad,
            };
            const tabsNewsData = content.newsStream;
            const milCommitData = content.milComment;
            const zhuantiData = content.zhuanti;
            const shougongtuijianweiData = content.shougongtuijianwei;
            const videoHighlightsData = content.videoHighlights;
            const footerData = { footer: content.footer };
            const friends = content.friends;
            const etcAdData = {
                float1Ad: content.float1Ad,
                float2Ad: content.float2Ad,
                float3Ad: content.float3Ad,
                float4Ad: content.float4Ad,
                float5Ad: content.float5Ad,
            };

            return (
                <Fragment>
                    <ChipEdit transform={transform} />
                    {/* 顶部 导航、广告和logo */}
                    <Header content={headerData} />
                    <NoSSR onSSR={<div className={styles.topAd} />}>
                        <Ad styleName={styles.topAd} content={content.headAd} />
                    </NoSSR>
                    <Logo content={logoData} />
                    <Chip id={30050} type="struct" title="导航栏" groupName="导航栏" content={navData}>
                        <Nav />
                    </Chip>
                    {/* main */}
                    <div className={classNames(styles.mb40, styles.main)}>
                        <NewsAndSlider content={newsAndSliderData} />
                        <div className={'clearfix'}>
                            <FourRecommendNews content={fourRecommendNewsData} />
                            <SurveyAndVideo content={surveyAndVideoData} />
                            <Leaderboard content={leaderboardData} />
                        </div>
                    </div>
                    <div className={classNames(styles.mb30, styles.main, 'clearfix')}>
                        <div className={styles.boxLeft}>
                            <NoSSR onSSR={<div className={styles.middleAd1} />}>
                                <Ad styleName={styles.middleAd1} content={content.sizeW650H90Ad} />
                            </NoSSR>
                            {/* 选项卡新闻 */}
                            <TabsNews content={tabsNewsData} ad={content.newsStreamAd} />
                        </div>
                        <div className={styles.boxRight}>
                            <NoSSR onSSR={<div className={styles.middleAd1} />}>
                                <Ad styleName={styles.middleAd1} content={content.sizeW300H90Ad} />
                            </NoSSR>
                            {/* 凤凰军评 */}
                            <MilCommit content={milCommitData} />
                            {/* 专题 */}
                            {/* <Zhuanti content={zhuantiData} /> */}
                            <Chip
                                id={287708}
                                type="recommend"
                                title="军事首页-专题-推荐位"
                                groupName="军事首页-专题-推荐位"
                                content={zhuantiData}>
                                <Zhuanti />
                            </Chip>
                            <Chip
                                id={25016}
                                type="static"
                                title="军事首页-二维码"
                                groupName="军事首页-二维码"
                                content={shougongtuijianweiData}>
                                <HtmlRegion />
                            </Chip>
                        </div>
                    </div>
                    <NoSSR onSSR={<div className={styles.bottomAd} />}>
                        <Ad styleName={styles.bottomAd} content={content.bottom1Ad} />
                    </NoSSR>
                    {/* 视频集锦 */}
                    <VideoHighlights content={videoHighlightsData} />
                    <NoSSR onSSR={<div className={styles.bottomAd2} />}>
                        <Ad styleName={styles.bottomAd2} content={content.bottom2Ad} />
                    </NoSSR>
                    <div className={styles.footer}>
                        <div className={styles.friends}>
                            <Chip id={25018} type="static" title="合作伙伴" groupName="合作伙伴" content={friends}>
                                <HtmlRegion />
                            </Chip>
                        </div>
                        <NoSSR onSSR={<div className={styles.footerAd} />}>
                            <Ad styleName={styles.footerAd} content={content.footerAd} />
                        </NoSSR>
                        {/* 底部链接 */}
                        <BottomFooter content={footerData} />
                    </div>
                    {/* 广告 */}
                    <EtcAd content={etcAdData} />
                </Fragment>
            );
        }
    },
);

export { Layout };
export default Layout;
