import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { classNames } from '@ifeng-fe/container_channel/common/client/utils/classnames';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

class VideoHighlights extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    // 渲染列表
    listView(list) {
        return list.map((item, index) => {
            const image = formatImage(item.thumbnails, 176, 120);
            const url = formatUrl(item.url);

            return (
                <li key={index} className={index === 0 ? undefined : styles.ml30}>
                    <a className={styles.imgLink} href={url} title={item.title} target="_blank" rel={relText}>
                        <img src={image} alt={item.title} width={176} height={120} />
                        <i className={styles.play} />
                    </a>
                    <p className={styles.link}>
                        <a href={url} title={item.title} target="_blank" rel={relText}>
                            {item.title}
                        </a>
                    </p>
                </li>
            );
        });
    }
    render() {
        const { content } = this.props;

        return (
            <div className={styles.box}>
                <h4 key="title" className={styles.title}>
                    <a href="#" target="_blank" rel={relText}>
                        视频集锦
                    </a>
                </h4>
                <ul className={classNames(styles.list, 'clearfix')}>{this.listView(content)}</ul>
            </div>
        );
    }
}

export default errorBoundary(VideoHighlights);
