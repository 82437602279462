import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

class SurveyContent extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object,
        valueCheck: PropTypes.array,
        selectValue: PropTypes.object,
        resultShow: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    handleSelect = e => {
        const { selectValue } = this.props;
        const type = e.currentTarget.type;
        const value = e.currentTarget.value;
        const qid = e.currentTarget.attributes.qid.value;

        if (type === 'radio') {
            selectValue[qid] = value;
        } else if (type === 'checkbox') {
            if (selectValue[qid]) {
                if (selectValue[qid].includes(value)) {
                    selectValue[qid].splice(selectValue[qid].indexOf(value), 1);
                } else {
                    selectValue[qid].push(value);
                }
            } else {
                selectValue[qid] = [];
                selectValue[qid].push(value);
            }
            selectValue[qid] = [...new Set(selectValue[qid])];
        }

        this.setState({ selectValue });
    };

    render() {
        const { data, valueCheck, resultShow, disabled } = this.props;

        // 0 已结束 1 进行中
        const status = data.surveyinfo.expire;

        const result = data.result;
        const list = [];

        result.slice(0, 1).forEach((item, index) => {
            // single 单选 multiple 复选
            const choosetype = item.resultArray.choosetype;

            const qid = item.resultArray.questionid;

            const props = {};

            if (status === '0') {
                props['disabled'] = 'disabled';
            }

            list.push(
                <li key={index} className={styles.item}>
                    <h3 style={{ color: valueCheck.includes(index) ? '#f54343' : '' }}>
                        {index + 1}. {item.resultArray.question}
                        {valueCheck.includes(index) ? <span>(此问必答)</span> : ''}
                        {choosetype === 'single' ? '' : <span>(多选)</span>}
                    </h3>
                    {item.resultArray.option.map((item, index) => (
                        <div key={index} className={styles.option}>
                            <label className={styles.label}>
                                <input
                                    className={styles.input}
                                    type={choosetype === 'single' ? 'radio' : 'checkbox'}
                                    value={item.itemid}
                                    qid={qid}
                                    name={`sur[${qid}][]`}
                                    disabled={disabled}
                                    onChange={this.handleSelect}
                                    {...props}
                                />
                                <span className={styles.title} style={{ width: resultShow ? '280px' : '' }}>
                                    {item.title}
                                </span>
                            </label>
                        </div>
                    ))}
                </li>,
            );
        });

        return (
            <form className={styles.survey_form}>
                <ul className={styles.survey_list}>{list}</ul>
            </form>
        );
    }
}

export default SurveyContent;
