import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import { formatUrl } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { Event } from '@ifeng-fe/ui_base';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/handleAd';

// 大标题推荐位
class BigTitleRecommend extends PureComponent {
    static propTypes = { content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]) };

    render() {
        const { title, url } = Array.isArray(this.props.content) ? this.props.content[0] || {} : this.props.content;

        return (
            <h1 className={styles.newsFirstTitle}>
                <a href={url} target="_blank" title={title} rel={relText}>
                    {title}
                </a>
            </h1>
        );
    }
}

// 推荐位
class Recommend extends PureComponent {
    static propTypes = { content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]) };

    render() {
        const { title, url } = Array.isArray(this.props.content) ? this.props.content[0] || {} : this.props.content;

        return (
            <p className={styles.tuijian}>
                <a href={url} target="_blank" title={title} rel={relText}>
                    {title}
                </a>
            </p>
        );
    }
}

class Basic extends PureComponent {
    static propTypes = { content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]) };

    render() {
        const { title, url } = Array.isArray(this.props.content) ? this.props.content[0] || {} : this.props.content;

        return (
            <p className={styles.newsItem}>
                <a href={url} target="_blank" title={title} rel={relText}>
                    {title}
                </a>
            </p>
        );
    }
}

class News extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
        ad: PropTypes.object,
    };

    constructor(...argu) {
        super(...argu);

        const { newsRecommend0, newsRecommend1, newsRecommend2, newsRecommend3, newsSelectedPool } = this.props.content;

        this.state = {
            content: this.formatData(newsRecommend0, newsRecommend1, newsRecommend2, newsRecommend3, newsSelectedPool),
        };
    }
    componentDidMount() {
        const { ad } = this.props;

        // 插入广告
        if (ad) this.getAd(ad);
    }
    event = new Event();
    // 获取广告
    async getAd(ad) {
        const len = this.state.content.length;
        const callback = await handleAd(ad);

        callback(ad.data, this.event, this.insert);

        this.event.trigger('init', { len, index: 0 });
    }
    insert = (insertArr, replaceArr, adFn) => {
        console.log('Ten Recommend Callback.', insertArr, replaceArr, adFn);

        if (adFn && typeof adFn === 'function') {
            const { content } = this.state;

            const infoData = [...content];
            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    content: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }
    };
    formatData(newsRecommend0, newsRecommend1, newsRecommend2, newsRecommend3, newsSelectedPool) {
        const result = [];
        let i = 0;
        let k = 0;

        while (i < 12) {
            let item = null;

            if (i === 0 && newsRecommend0.length > 0) {
                item = newsRecommend0[0];
            } else if (i === 5 && newsRecommend1.length > 0) {
                item = newsRecommend1[0];
            } else if (i === 4 && newsRecommend2.length > 0) {
                item = newsRecommend2[0];
            } else if (i === 9 && newsRecommend3.length > 0) {
                item = newsRecommend3[0];
            } else {
                item = newsSelectedPool[k];
                k += 1;
            }

            result.push(item);
            i += 1;
        }

        return result;
    }
    listView() {
        const { content } = this.state;
        const element = [];

        for (let i = 0, j = content.length; i < j; i++) {
            const item = content[i];
            const url = formatUrl(item.url);
            const props = { url, title: item.title };

            if (item.type === 'ad') {
                element.push(<p className={styles.newsItem} key={i} ref={item.ref} />);
                if (i === 5) element.push(<hr key="line" className={styles.line} />);
                continue;
            }

            // 大标题
            if (i === 0) {
                // 大标题
                element.push(
                    <Chip key={i} id="55037" type="recommend" title="要闻推荐位1" groupName="要闻" content={props}>
                        <BigTitleRecommend />
                    </Chip>,
                );
            } else if (i === 6) {
                element.push(
                    <Chip key={i} id="75003" type="recommend" title="要闻推荐位2" groupName="要闻" content={props}>
                        <BigTitleRecommend />
                    </Chip>,
                );
            } else if (i === 4) {
                // 推荐位
                element.push(
                    <Chip key={i} id="75004" type="recommend" title="要闻推荐位3" groupName="要闻" content={props}>
                        <Basic />
                    </Chip>,
                );
            } else if (i === 10) {
                // 推荐位
                element.push(
                    <Chip key={i} id="75005" type="recommend" title="要闻推荐位4" groupName="要闻" content={props}>
                        <Basic />
                    </Chip>,
                );
            } else if (i === 1) {
                element.push(
                    <Chip key={i} id="75014" type="recommend" title="要闻 干预10条" groupName="要闻" content={props}>
                        <Basic />
                    </Chip>,
                );
            } else {
                element.push(<Basic key={i} content={props} />);
            }

            if (i === 5) element.push(<hr key="line" className={styles.line} />);
        }

        return element;
    }
    render() {
        return <div className={styles.news}>{this.listView()}</div>;
    }
}

export default errorBoundary(News);
