import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import styles from './index.css';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import Survey from '../survey';

class SurveyAndVideo extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    listView(list) {
        const element = [];

        for (let i = 1, j = list.length; i < j; i++) {
            const item = list[i];
            const url = formatUrl(item.url);

            element.push(
                <li key={i}>
                    <i className={styles.play} />
                    <a href={url} target="blank" rel={relText} title={item.title}>
                        {item.title}
                    </a>
                </li>,
            );
        }

        return element;
    }
    render() {
        const {
            content: { milVideo, survey },
        } = this.props;
        const { title, summary, thumbnails, url } = milVideo[0];
        const image = thumbnails ? formatImage(thumbnails, 240, 124) : '';
        const url2 = formatUrl(url);

        return (
            <div className={styles.box}>
                {/* 调查 */}
                <div key="survey" className={styles.survey}>
                    <Chip
                        id="30058"
                        type="struct"
                        title="调查"
                        groupName="调查"
                        content={{ attachmentId: survey.surveyId }}>
                        <Survey />
                    </Chip>
                </div>
                {/* 军事视频 */}
                <div key="milVideo">
                    <h4 className={styles.milVideoTitle}>
                        <a href="//news.ifeng.com/listpage/7331/1/list.shtml" target="_blank" rel={relText}>
                            军事视频
                        </a>
                    </h4>
                    <a className={styles.newsThumbnails} href={url2} target="blank" rel={relText}>
                        <img src={image} alt={summary || title} width={240} height={124} />
                        <p className={styles.newsThumbnailsTitle}>{title}</p>
                    </a>
                </div>
                <ul className={styles.list}>{this.listView(milVideo)}</ul>
            </div>
        );
    }
}

export default errorBoundary(SurveyAndVideo);
