import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Chip from 'Chip';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';
import Nav from '@ifeng-fe/container_channel/common/client/components/nav/index';

/**
 * 定义 Header 组件
 */
const Header = errorBoundary(
    class extends React.PureComponent {
        static propTypes = {
            content: PropTypes.object,
        };

        render() {
            const { content } = this.props;

            return (
                <div className={styles.nav_box}>
                    <div className={styles.main_nav}>
                        <Chip id={20002} type="struct" title="导航" groupName="头部" content={content.nav}>
                            <Nav limit={18} />
                        </Chip>
                        <div className={styles.userinfo}>
                            <NoSSR>
                                <UserInfo />
                            </NoSSR>
                        </div>
                    </div>
                </div>
            );
        }
    },
);

export { Header };
export default Header;
